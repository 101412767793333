@import '../../../styles/variables.css';

.container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;

  @media (--tablet-landscape-up) {
    display: block;
    min-height: auto;
  }
}

.inputWrapper {
  position: relative;
  height: 65px;
  margin-bottom: 29px;
  border: 2px solid var(--white);

  @media (--tablet-landscape-up) {
    max-width: 400px;
    height: 42px;
    margin-bottom: 25px;
  }
}

.loading {
  background-image: url('/img/svg/loader.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.input {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 12px 60px 12px 46px;
  outline: 0;
  border: 0;
  @mixin font-family-title;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--white);
  background-image: var(--search-icon-white);
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: 16px 18px;
  background-color: transparent;

  @media (--tablet-landscape-up) {
    padding-left: 18px;
    font-size: 18px;
    line-height: 21px;
    background-image: none;
  }

  &::placeholder {
    padding-left: 10px;
    @mixin font-family-title;
    @mixin font-medium;
    color: var(--white);

    @media (--tablet-landscape-up) {
      color: #413f3f;
    }
  }
  &:-ms-input-placeholder {
    padding-left: 10px;
    @mixin font-family-title;
    @mixin font-medium;
    color: var(--white);

    @media (--tablet-landscape-up) {
      color: #413f3f;
    }
  }
  &::-ms-input-placeholder {
    padding-left: 10px;
    @mixin font-family-title;
    @mixin font-medium;
    color: var(--white);

    @media (--tablet-landscape-up) {
      color: #413f3f;
    }
  }
}

.inputClearBtn {
  @mixin btn-reset;
  position: absolute;
  top: 6px;
  right: 11px;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    font-size: 10px;
    line-height: 13px;
  }
}

.measureBtn {
  @mixin btn-reset;
  margin-bottom: 19px;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--white);
}

.measureBtn {
  margin-left: 20px;
  text-align: left;

  @media (--tablet-landscape-up) {
    margin-left: 0;
  }
}

.results {
  display: flex;
  max-width: 500px;
  padding-left: 20px;

  @media (--tablet-landscape-up) {
    padding-left: 0;
  }
}

.col {
  width: 50%;
  padding-right: 45px;

  @media (--tablet-landscape-up) {
    padding-right: 57px;
  }
}

.gender {
  margin-bottom: 21px;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--white);
}

.result {
  display: block;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.loading {
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.2px;
  color: var(--white);
}

@import '../../styles/variables.css';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* transform: translateX(-50%); */
  height: var(--header-height);
  /* transition: background-color 0.2s; */
  z-index: var(--z-overlay);
}

.containerWithFlash {
  height: var(--header-with-flash-height);
}

.headerContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--header-padding);
  @mixin max-width var(--container-max-width);

  @media (--tablet-landscape-up) {
    display: flex;
    justify-content: flex-start;
  }
}

/* .menuVisible {
  .buttons {
    display: none;
  }
} */

.mobileMenuTitle {
  display: block;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    display: none;
  }
}

.backBtn,
.backBtnMobile {
  @mixin btn-reset;
  width: var(--btn-header-size);
  height: 27px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  transition: background-image 0.2s ease-in-out;

  @media (--tablet-landscape-up) {
    margin-left: 0;
    margin-right: 5px;
    background-position: center 2px;
    background-size: contain;
  }
}

/* NOTE: IOS has a wierd bug that is not swapping out
the css var background-image event though it shows in inspect.
Setting normal icons here. */
body {
  .menuBtnDesktopClose {
    @media (--tablet-landscape-up) {
      background-position: center 4px;
      background-size: 20px;

      [data-header-color='light']:not([data-header-scrolled='true']) &,
      [data-header-color='light-ui-dark-logo']:not([data-header-scrolled='true'])
        &,
      [data-header-menu-open] & {
        background-image: url(/img/svg/close-icon-white.svg);
      }
    }
  }
}

.backBtn {
  position: absolute;
  top: calc(var(--header-height) - 5px);
  left: var(--container-padding);
  margin: 0;
  background-image: var(--cursor-back-icon-white);
  background-position: 7px center;
  background-size: 6px;
  z-index: var(--z-default);

  @media (--tablet-landscape-up) {
    top: 27px;
    left: 18px;
    background-position: center 4px;
    background-size: 12px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 80%;
    height: 80%;
    border-radius: 100%;
    border: 1px solid var(--white);

    @media (--tablet-landscape-up) {
      content: none;
    }
  }
}

.backBtnMobile {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  background-image: var(--cursor-back-icon-white);
  background-size: 18px 18px;

  @media (--tablet-landscape-up) {
    display: none;
  }
}

.links {
  display: none;

  @media (--tablet-landscape-up) {
    display: flex;
  }
}

.linksMain {
  opacity: 0;
  transition: opacity 0.2s;
}

.linksMainVisible {
  opacity: 1;
}

a.link {
  position: relative;
  margin-right: 24px;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #000;
  color: var(--header-ui-color);
  transition: color 0.2s;

  &:hover::before {
    opacity: 1;
  }

  &::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: var(--header-ui-color);
    transition: opacity 0.2s;
  }
}

.linkActive {
  &::before {
    opacity: 1;
  }
}

body .logo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  @media (--tablet-landscape-up) {
    right: var(--container-padding);
    transform: translate(0, -50%);

    [data-results-visible] & {
      display: none;
    }
  }
}

.logoMobile {
  @media (--tablet-landscape-up) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}

.menu {
  background-color: rgba(0, 0, 0, 0.8);

  &::-webkit-scrollbar-thumb {
    background-color: var(--white);
  }
}

.menuContainer {
  @mixin max-width var(--container-max-width);
  height: 100%;
}

.menuContainerWithFlash {
  .menuWrapper {
    padding-top: calc(var(--header-with-flash-height) + 34px);

    @media (--tablet-landscape-up) {
      padding-top: calc(40px + var(--header-with-flash-height));
    }
  }
}

.menuWrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(var(--header-height) + 34px) var(--container-padding) 30px;
  background-color: var(--black);

  @media (--tablet-landscape-up) {
    display: flex;
    width: 50%;
    min-width: 670px;
    padding: calc(40px + var(--header-height)) 0 20px
      calc(var(--container-padding) + 35px);
    z-index: var(--z-default);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: var(--black);
  }
}

.menuCloseBtn {
  left: initial;
  right: 0;
  width: 50%;
}

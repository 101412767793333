@import '../../../styles/variables.css';

.container {
  @media (--tablet-landscape-up) {
    max-width: 362px;
  }
}

.hide {
  display: none;
}

.title,
.subtitle {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
    @mixin font-family-title;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--white);
  }
}

.title {
  margin-bottom: 0;
  @mixin font-boldest;
}

.subtitle {
  margin-bottom: 31px;
  @mixin font-thin;
}

.units {
  display: flex;
  margin: 27px 0;

  @media (--tablet-landscape-up) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

.unit {
  opacity: 0.5;
  @mixin btn-reset;
  margin-right: 20px;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--white);
  transition: opacity 0.2s;

  @media (--tablet-landscape-up) {
    margin-left: 10px;
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.unitActive {
  opacity: 1;
}

.measurements {
  position: relative;
  margin-bottom: 32px;
}

.measurement {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.measurementTitle {
  flex-basis: 100%;
  margin-bottom: 14px;
  @mixin font-family-title;
  font-size: 14px;
  @mixin font-bold;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--white);
}

.measurementTitleDesktop {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}

.measurementCol {
  position: relative;
  width: 48.5%;
  height: 40px;
  border: 1px solid var(--white);
}

.measurementColGender {
  @media (--tablet-landscape-down) {
    height: 53px;

    .measurementLabel {
      @mixin font-boldest;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 1px;
    }
  }
}

.measurementLabel,
.measurementRadio,
.measurementInput {
  @mixin btn-reset;
  width: 100%;
  @mixin font-family-title;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: default;
  color: var(--white);

  &::placeholder {
    opacity: 0.74;
    color: var(--white);
  }
  &:-ms-input-placeholder {
    opacity: 0.74;
    color: var(--white);
  }
  &::-ms-input-placeholder {
    opacity: 0.74;
    color: var(--white);
  }
}

.measurementLabel {
  opacity: 0.74;
  padding: 11px 14px;
}

.measurementRadio {
  opacity: 0;
  visibility: hidden;

  + .measurementLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    @media (--tablet-landscape-down) {
      @mixin font-thin;
    }
  }

  &:checked + .measurementLabel {
    opacity: 1;
    color: var(--black);
    background-color: var(--white);

    @media (--tablet-landscape-down) {
      @mixin font-boldest;
    }
  }
}

.submitBtn {
  @mixin btn-reset;
  width: 100%;
  height: 51px;
  margin-bottom: 30px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: var(--black);
  background-color: var(--white);
}

.nameBtn {
  @mixin btn-reset;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--white);
}

body .selectOptions {
  z-index: var(--z-mobile-menu);
}

.results {
  position: absolute;
  left: 100%;
  top: 0;
  padding: 30px;
  font-size: 12px;
  background-color: var(--white);
}

.resultsTitle {
  margin-bottom: 30px;
}

.result {
  margin-bottom: 7px;
}

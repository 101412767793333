@import '../../styles/variables.css';

.container {
  @mixin btn-reset;
  width: 36px;
  height: 36px;

  @media (--tablet-landscape-up) {
    display: none;
  }

  svg {
    display: block;
    margin-left: auto;
    transition: 0.2s;
  }
}

@import '../../styles/variables.css';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.hasError .input {
  border-color: red;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hasOptionsDisplayer {
  .input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.hasLabel.hasValue {
  .label {
    opacity: 1;
    visibility: visible;
  }
}

.inputContainer {
  position: relative;
}

.input {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 11px 15px;
  border: none;
  border-radius: 0;
  @mixin font-family-title;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.74);
  background-color: transparent;
  transition: padding 0.2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: var(--space-sm);

    @media (--desktop-up) {
      right: var(--space-md);
    }
  }

  &::before {
    margin-top: -5px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--white);
  }

  &::after {
    margin-top: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--white);
  }

  &:focus {
    outline: none;
  }
}

.label {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  top: 6px;
  left: 1px;
  padding: 0 15px;
  font-size: 11px;
  transition: all 0.2s ease-in-out;
}

.options {
  position: absolute;
  left: -1px;
  top: 100%;
  width: calc(100% + 2px);
  max-height: 211px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--black);
  border: 1px solid var(--white);

  &::-webkit-scrollbar-thumb {
    background-color: var(--white);
  }
}

.option {
  display: block;
  width: 100%;
  margin: 0;
  padding: var(--space-md);
  @mixin font-family-title;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.74);
  border-bottom: 1px solid #ccc;

  @media (--tablet-portrait-up) {
    padding: 8px 15px;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &.optionSelected,
  &:hover {
    @mixin font-regular;
  }

  &.optionDisabled {
    display: none;

    &:hover {
      @mixin font-thin;
    }
  }
}

.subOption {
  padding-left: 25px;
}

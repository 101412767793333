@import '../../styles/variables.css';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  z-index: var(--z-secondary);

  @media (--tablet-landscape-up) {
    display: none;
  }
}

.searchBtn {
  @mixin btn-reset;
  width: 219px;
  height: 45px;
  margin: 0 auto 33px;
  padding: 12px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.2px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  background-image: var(--search-icon-white);
  background-position: 9px 12px;
  background-repeat: no-repeat;
  border: 2px solid var(--white);
}

.menu {
  margin-bottom: 26px;
}

.menuTitle {
  margin-bottom: 26px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--white);
}

.menuLink {
  @mixin btn-reset;
  margin: 0 auto;
  @mixin font-family-menu;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2.5px;
  white-space: nowrap;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--white);
  color: transparent;
  transition: color 0.2s;

  @media (--mobile-portrait-up) {
    font-size: 27px;
    line-height: 32px;
  }

  @media (--tablet-portrait-up) {
    font-size: 30px;
    line-height: 36px;
  }

  /* &:hover {
    color: var(--white);
  } */

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.main {
  height: calc(100vh- calc(var(--header-height) + 34px));
}

.locations {
}

.region {
  margin-bottom: 45px;
}

.regionName {
  margin-bottom: 20px;
  @mixin font-family-title;
  @mixin font-bolder;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--white);
}

body .footer {
  position: relative;
  margin-top: 20px;
  padding-top: 5px;
  border-top: 1px solid #383838;
  background-color: var(--black);

  > div {
    padding: 20px 0 0 0;
  }
}

@import '../../styles/variables.css';

.container {
  @mixin btn-reset;
  width: var(--btn-header-size);
  height: var(--btn-header-size);

  @media (--tablet-landscape-up) {
    display: none;
  }

  svg {
    display: block;
    width: 70%;
    height: 70%;
    margin-left: auto;
    transition: 0.2s;
  }
}

@import '../../../styles/variables.css';

.container {
  max-width: 362px;
}

.results {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  z-index: var(--z-secondary);
}

.title,
.subtitle {
  display: block;
  @mixin font-family-title;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--white);
}

.title {
  @mixin font-bolder;
  margin-bottom: 5px;
}

.subtitle {
  @mixin font-thin;
}

.measurements {
  display: flex;
  flex-wrap: wrap;
  margin: 28px 0 20px;

  @media (--tablet-landscape-up) {
    margin: 28px 0 25px;
  }
}

.measurement {
  display: flex;
  width: 35%;
  margin-bottom: 17px;
}

.measurementLabel,
.measurementValue,
.noItems,
.btn {
  display: block;
  @mixin font-family-title;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--white);
}

.measurementLabel {
  margin-right: 8px;
  @mixin font-bolder;
}

.measurementValue {
  opacity: 0.74;
  @mixin font-thin;
}

.noItems {
  margin: 42px 0;
}

.btns {
  display: flex;
}

.btn {
  @mixin btn-reset;
  @mixin font-bolder;
  margin-right: 33px;
  padding: 5px 10px;
  color: var(--black);
  background-color: var(--white);
}

.modelsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
  padding-top: 43px;
  border-top: 1px solid var(--white);

  @media (--tablet-landscape-up) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    margin-top: 0;
    padding-top: 0;
    border: none;
    z-index: var(--z-default);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--header-height) * -1);
    left: 50%;
    width: 50%;
    height: var(--header-height);
    background-color: var(--white);
  }
}

.models {
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 17px;
  width: 100%;
  height: 100%;

  @media (--tablet-landscape-up) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 15px;
    padding: 36px;
    background-color: var(--white);
  }
}

.model {
  margin-bottom: 12px;

  li,
  li span {
    color: var(--white);
  }

  * {
    color: var(--white);

    @media (--tablet-landscape-up) {
      color: var(--black);
    }
  }
}

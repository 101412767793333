@import '../../styles/variables.css';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 14px 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--white);
  background: color(#000 a(50%));
  transition: all 0.2s ease;
  z-index: var(--z-header);
  height: var(--flash-message-height);

  @media (--tablet-portrait-up) {
    padding: 14px 90px;
    text-align: center;
  }

  p {
    margin: 0;
    color: var(--white);
  }

  a {
    color: var(--white);
    border-bottom: 1px solid var(--white);
  }

  strong {
    font-weight: 700;
  }
}

.content {
  margin-right: 35px;

  @media (--tablet-portrait-up) {
    margin: 0;
  }
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  @mixin font-family-title;
  @mixin font-thin;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-portrait-up) {
    right: 30px;
    font-size: 14px;
  }
}

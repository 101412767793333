@import './fonts.css';
@import './mixins.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  @mixin font-family-title;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-color);

  /*
   * Layout
   * ======================================================================== */

  --container-max-width: 1815px;
  --scrollbar-width: 6px;
  --header-height: 66px;
  --flash-message-height: 91px;
  --header-with-flash-height: calc(
    var(--header-height) + var(--flash-message-height)
  );
  @media (--tablet-landscape-up) {
    --header-height: 81px;
    --flash-message-height: 49px;
    --header-with-flash-height: calc(
      var(--header-height) + var(--flash-message-height)
    );
  }
  --board-menu-height: 70px;
  --header-padding: 15px var(--container-padding);
  @media (--tablet-landscape-up) {
    --header-padding: 27px var(--container-padding);
  }

  /*
   * Spacing
   * ======================================================================== */

  --space-unit: 1em;
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --container-padding: 21px;
  @media (--tablet-landscape-up) {
    --container-padding: 50px;
  }

  /*
   * Icons
   * ======================================================================== */

  --search-icon-white: url(/img/svg/search-white.svg);
  --close-icon-black: url(/img/svg/close-icon-dark.svg);
  --close-icon-white: url(/img/svg/close-icon-white.svg);
  --cursor-back-icon-black: url(/img/svg/chevron-back-black.svg);
  --cursor-back-icon-white: url(/img/svg/chevron-back-white.svg);
  --cursor-back-icon-grey: url(/img/svg/chevron-back-grey.svg);
  --menu-mobile-icon-white: url(/img/svg/menu-mobile-icon-white.svg);

  /*
   * Colours
   * ======================================================================== */

  --black: #000;
  --white: #fff;
  --primary-color: var(--black);
  --package-bg: #f6f6f6;
  --package-border: #ededed;
  --header-ui-color: var(--black);
  --header-logo-color: var(--black);
  --header-slider-ui-color: var(--black);

  /*
   * Apply Section Colours
   * ======================================================================== */
  --apply-background: #f0f0f0;
  --apply-button-color: #c2c2c2;
  --apply-label-color: #acacac;
  --apply-heading-color: #464646;
  --button-dark-grey: #999;

  /*
   * Buttons
   * ======================================================================== */

  --btn-font-size: 1em;
  --btn-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-md: calc(var(--btn-font-size) + 0.2em);
  --btn-lg: calc(var(--btn-font-size) + 0.4em);

  --btn-header-size: 27px;

  /*
   * Z Indexes
   * ======================================================================== */

  --z-behind: -1;
  --z-default: 10;
  --z-secondary: 20;
  /* --z-dropdown: 1; */
  /* --z-menu: 2; */
  /* --z-mobile-menu-close: 3; */
  /* --z-header: 4; */
  --z-mobile-menu: 50;
  --z-overlay: 90;
  --z-overlay-content: 100;
  --z-lightbox: 110;
  --z-vegeta: 99999;

  /*
   * Lightbox
   * ======================================================================== */

  --lightbox-width: 360px;
  --lightbox-bg: var(--package-bg);
  --lightbox-border: var(--package-border);
  --lightbox-added: #cfb1e5;
  --lightbox-blue: #c5dbf6;

  /*
   * Animations
   * ======================================================================== */

  --cloudinary-transition: opacity 0.4s 0.5s;
}

[data-header-color='light'] {
  --header-ui-color: var(--white);
  --header-logo-color: var(--white);
  --header-slider-ui-color: var(--white);
}
[data-header-color='light-ui-dark-logo'] {
  --header-ui-color: var(--white);
  --header-slider-ui-color: var(--white);
}
[data-header-color='dark-ui-light-logo'] {
  --header-ui-color: var(--black);
  --header-logo-color: var(--white);
  --header-slider-ui-color: var(--black);
}
[data-header-scrolled] {
  --header-ui-color: var(--black);
  --header-logo-color: var(--black);
}
[data-header-menu-open] {
  --header-ui-color: var(--white);
  --header-logo-color: var(--black);
  --header-slider-ui-color: var(--white);
}

/* TODO: When a fix is found for the not pure selector bug (https://github.com/vercel/next.js/issues/10142)
move this to SliderBlock slick.css */
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  /* user-select: none; */
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }

  &.slick-current {
    z-index: var(--z-default);
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 40px 0;
  text-align: center;

  li {
    opacity: 0.25;
    display: inline-block;
    max-width: 40px;
    width: 5%;
    height: 2px;
    margin: 0 8px;
    cursor: pointer;
    background-color: var(--white);

    @media (--tablet-landscape-up) {
      margin: 0 10px;
    }

    &.slick-active {
      opacity: 1;
    }

    button {
      @mixin btn-reset;
      width: 10px;
      height: 10px;
      font-size: 0;
    }
  }
}

.video-js .vjs-big-play-button {
  display: none;
}

.video-js.vjs-has-started button.playButton {
  display: none;
}

.video-js button.playButton {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  /* z-index: var(--zindex-video-play-button); */

  &:hover {
    .playButtonContent {
      background: #272727;
    }

    .playIcon {
      color: var(--white);
    }
  }
}

/* Slideshow */

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.react-multiple-carousel__arrow::before {
  display: block;
  position: relative;
  font-size: 20px;
  font-family: revicons;
  text-align: center;
  color: var(--white);
  z-index: var(--z-secondary);
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}

.react-multiple-carousel__arrow--left::before {
  content: '\e824';
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}

.react-multiple-carousel__arrow--right::before {
  content: '\e825';
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}

.react-multi-carousel-dot button:hover {
  background: #080808;
}

.react-multi-carousel-dot--active button {
  background: #080808;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

/* Slideshow END */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: overlay;
  -webkit-overflow-scrolling: touch;

  @media (--tablet-portrait-up) {
    &::before {
      content: 'tablet';
    }
  }

  @media (--desktop-up) {
    &::before {
      content: 'desktop';
    }
  }

  &::before {
    display: none;
    content: 'mobile';
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 var(--space-md) 0;
  font-size: 16px;
  font-weight: 500;

  @media (--tablet-portrait-up) {
    margin-bottom: var(--space-lg);
  }
}

p {
  font-size: 14px;
  margin: 0;

  @media (--tablet-portrait-up) {
    font-size: 16px;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

ul {
  margin: 0 0 var(--space-sm) 0;
  padding: 0;
  list-style-type: none;

  @media (--tablet-portrait-up) {
    margin-bottom: var(--space-md);
  }
}

li {
  margin: 0;
  font-size: 14px;

  @media (--tablet-portrait-up) {
    font-size: 16px;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  @mixin font-family-title;
}

select {
  @mixin btn-reset;
  width: 100%;
  box-shadow: none;
  font-size: 14px;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

blockquote {
  margin: 0;
}

figure {
  margin: 0;
}

hr {
  width: 100%;
  max-width: 400px;
  margin: var(--space-md) 0;
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.5);

  @media (--tablet-portrait-up) {
    margin: var(--space-lg) var(--space-xs) var(--space-lg) 0;
  }
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: rgba(256, 256, 256, 0);
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--black);
}

#__next {
  height: 100%;
}

/* Layout */

#layout {
  opacity: 1;
  transition: opacity 0.5s;

  &.layoutFade {
    opacity: 0;
  }
}

/* Headroom */

.headroom-wrapper {
  /* Height is not being removed when unFix is set on <Headroom /> */
  height: 0 !important;
}

.headroom {
  /* width: calc(100vw - var(--scrollbar-width)); */
}

.headroom--unpinned,
.headroom--unpinned.headroom--scrolled {
  > div {
    transform: translateY(-100%);
  }
}

.headroom--pinned {
  &.headroom--scrolled {
    @media (--tablet-landscape-up) {
      background-color: var(--white);
    }
  }

  > div {
    position: static;
    width: 100vw;
    transform: translateY(0);
  }
}

/* Google Map */

.gm-style .gm-style-iw-c {
  border-radius: 0;
  text-align: center;
}

.gm-style .gm-style-iw-c p {
  line-height: 1.3;
  padding: 10px 30px;
}

.gm-style .gm-style-iw-a {
  margin-top: -30px;
}

/* Highlight Block */

.highlightBlock {
  margin-top: 25px;

  @media (--tablet-landscape-up) {
    margin-top: 50px;
  }

  + .showcaseBlock {
    margin-top: 0;
  }
}

/* Showcase Block */

.showcaseBlock {
  overflow: hidden;
  margin-top: 25px;

  @media (--tablet-landscape-up) {
    margin-top: 50px;
  }

  + .highlightBlock {
    margin-top: 0;
  }

  + .articleGridBlock,
  + .articleGrid {
    margin-top: 25px;

    @media (--tablet-landscape-up) {
      margin-top: 50px;
    }
  }
}

/* Article Block */

.articleGridBlock + .articleGrid {
  margin-top: 25px;

  @media (--tablet-landscape-up) {
    margin-top: 50px;
  }
}

/* Gallery Block */

.galleryBlock {
  overflow: hidden;
}

/* Text Block */

.textBlock + .galleryStackBlock > div {
  padding-top: 0;
}

/* Gallery Stack Block */

.galleryStackBlock + .galleryStackBlock > div,
.galleryStackBlock + .textBlock > div {
  padding-top: 0;
}

/* Model Page */

#portfolio {
  display: block;
}

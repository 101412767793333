@import '../../styles/variables.css';

.container {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
    position: relative;
    margin-right: 5px;

    &:hover {
      .btn {
        transform: rotate(90deg);
      }

      .tooltip {
        &::before,
        &::after {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }
}

.btn {
  display: block;
  width: var(--btn-header-size);
  height: var(--btn-header-size);
  transform-origin: 50% 50%;
  transition: transform 0.4s;

  svg {
    @mixin svg-size;
  }
}

.tooltip {
  position: relative;

  &::before,
  &::after {
    opacity: 0;
    visibility: hidden;
    display: block;
    position: absolute;
    transform: translateY(4px);
    transition: opacity 0.7s, visibility 0.7s, transform 0.7s;
    z-index: 1;
  }

  &::before {
    content: 'Shortlist';
    position: absolute;
    top: calc(100% + 8px);
    left: -28.5px;
    width: 82px;
    height: 19px;
    @mixin font-family-title;
    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    color: #dadada;
    background-color: var(--black);
  }

  &::after {
    content: '';
    top: calc(100% + 3px);
    left: 7.5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--black);
  }
}

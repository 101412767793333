@import '../../styles/variables.css';

.container {
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar-thumb {
    background-color: var(--white);
  }
}

@import '../../styles/mixins.css';

.overlay,
.modalWrapper {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: var(--z-overlay-content);
}

.overlay {
  background-color: var(--black);
}

.modalWrapper {
}

.closeBtn {
  @mixin btn-reset;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-default);
}

@import '../../styles/variables.css';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: var(--z-secondary);

  @media (--tablet-landscape-up) {
    height: auto;
  }
}

.links {
  text-align: center;

  @media (--tablet-landscape-up) {
    text-align: left;
  }
}

.linksSec {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}

.link {
  display: block;
  @mixin font-family-menu;
  font-size: 36px;
  line-height: 33px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-shadow: -1px 0 var(--white), 0 1px var(--white), 1px 0 var(--white),
    0 -1px var(--white);
  color: var(--black);
  transition: color 0.2s, text-shadow 0.2s;

  @media (--tablet-landscape-up) {
    display: table;
  }

  &:not(:last-child) {
    margin-bottom: 13px;
  }

  &:hover {
    color: var(--white);
    text-shadow: -1px 0 var(--black), 0 1px var(--black), 1px 0 var(--black),
      0 -1px var(--black);
  }
}

.linkBtn {
  @mixin btn-reset;
}

.linkSec {
  display: block;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 16px;
  letter-spacing: 0.47px;
  text-transform: uppercase;
  color: var(--white);

  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

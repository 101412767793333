@import '../../styles/variables.css';

.container {
  display: none;

  @media (--tablet-landscape-up) {
    @mixin btn-reset;
    width: var(--btn-header-size);
    height: var(--btn-header-size);
  }

  svg {
    @mixin svg-size;
    transition: 0.2s;
  }
}
